import localforage from 'localforage';
import { buildSearchParams, parseSearchParams, SearchParams } from './searchParams';
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { immer } from 'zustand/middleware/immer';
import { create, useStore as useZustandStore } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { current } from 'immer';
import { useUser } from './user';

export function defaultSet(set, key) {
  return (data) => {
    return set((state) => {
      const x = current(state);
      state[key] = typeof data === 'function' ? data(state[key]) : data;
    });
  };
}

export function buildSetActions(set, state) {
  return Object.keys(state).reduce((actions, key) => {
    const actionKey = `set${key.charAt(0).toUpperCase() + key.slice(1)}`;
    actions[actionKey] = defaultSet(set, key);

    return actions;
  }, {});
}

export function buildSearchParamStorage({ ignoreKeys = [] } = {}) {
  return {
    getItem: async (key) => {
      const searchParams = new SearchParams();
      const searchParamsState = searchParams.getSerialized(key);
      const persistItem = JSON.parse(await localforage.getItem(key));
      const persistState = persistItem['state'];

      return JSON.stringify({
        ...persistItem,
        state: {
          ...persistState,
          ...searchParamsState,
        },
      });
    },
    setItem: async (key, value) => {
      const searchParams = new SearchParams();
      const searchParamsState = searchParams.getSerialized(key);
      let state = JSON.parse(value)['state'];

      Object.keys(state).forEach((stateKey) => {
        if (ignoreKeys.includes(stateKey)) {
          delete state[stateKey];
        }
      });

      const hasChanged = JSON.stringify(searchParamsState) != JSON.stringify(state);
      if (hasChanged) {
        searchParams.setSerialized(key, state);
        searchParams.push();
        await localforage.setItem(key, value);
      }
    },
    removeItem: async (name) => {
      await localforage.removeItem(name);
    },
  };
}

function defaultPartialize(state) {
  const { actions, _lastModified, ...rest } = state;
  return rest;
}

function defaultOnRehydrateStorage(state) {
  return (state, error) => {
    if (error) {
      console.error('an error happened during hydration', error);
    }

    state.actions.setHydrated(true);
  };
}

export const buildStore = (initialState) => {
  const createStore = (props, searchParamsState, buildActions, persistStore) => {
    let store = immer((set, get) => ({
      ...initialState,
      ...props,
      ...searchParamsState,
      ...(persistStore ? { _hasHydrated: false } : { _hasHydrated: true }),
      actions: {
        ...buildSetActions(set, initialState),
        ...buildActions?.(set, initialState),
        ...(persistStore
          ? {
              setHydrated: defaultSet(set, '_hasHydrated'),
              // setLastModified: defaultSet(set, '_lastModified'),
            }
          : {}),
      },
    }));

    if (!!persistStore) {
      const { persistKey, partialize, onRehydrateStorage, version } = persistStore;

      store = persist(store, {
        name: persistKey,
        storage: createJSONStorage(() => localforage),
        partialize: partialize || defaultPartialize,
        onRehydrateStorage: onRehydrateStorage || defaultOnRehydrateStorage,
        version: version || 0,
        migrate: (persistedState, currentVersion) => {
          if (currentVersion < version) {
            // Reset state
            return initialState;
          }

          return persistedState;
        },
      });
    }

    return create(store);
  };

  const Context = createContext();

  const useStore = (selector) => {
    const context = useContext(Context);
    if (!context) {
      throw new Error('useStore must be used within a ContextProvider');
    }

    return useZustandStore(context, selector);
  };

  const SearchParamsListener = ({ useSearchParams }) => {
    const partialize = useSearchParams.partialize || defaultPartialize;
    const state = useStore(partialize);

    useEffect(() => {
      if (!useSearchParams) return;
      buildSearchParams(state, useSearchParams.key);
    }, [JSON.stringify(state)]);
  };

  const ContextProvider = ({
    children,
    buildActions,
    persist: persistArg,
    useSearchParams,
    ...props
  }) => {
    // We should have different persist state between users
    // And development environment
    const { user } = useUser();
    let persist = persistArg
      ? {
          ...persistArg,
          persistKey: `${process.env.REACT_APP_BDD_API_TYPE}-${user.id}-${persistArg.persistKey}`,
        }
      : null;

    const searchParamsState = useSearchParams
      ? parseSearchParams(useSearchParams.key) || {}
      : {};

    const store = useRef(
      createStore(props, searchParamsState, buildActions, persist)
    ).current;

    return (
      <Context.Provider value={store}>
        {useSearchParams && <SearchParamsListener useSearchParams={useSearchParams} />}
        {children}
      </Context.Provider>
    );
  };

  const useHydration = () => {
    const [hydrated, setHydrated] = useState(false);

    useEffect(() => {
      const unsubFinishHydration = useStore.persist.onFinishHydration(() =>
        setHydrated(true)
      );
      setHydrated(useStore.persist.hasHydrated());

      return () => {
        unsubFinishHydration();
      };
    }, []);

    return hydrated;
  };

  return {
    Context,
    ContextProvider,
    useStore,
    useHydration,
  };
};
