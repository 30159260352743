import { lazy, Suspense, useState } from 'react';
import useDebounce from '../hooks/usedebounce';
import { useFilterContext } from './context';
import { Field } from './Field';
import { Text } from '../Text';
import { EMPTY_TEXT } from '../TextEditor';

const PlateTextEditor = lazy(() => import('components/bdd/TextEditor/PlateTextEditor'));

export const TextEditorFilter = ({
  id,
  label,
  cond = '=',
  onChange,
  meta,
  className,
}) => {
  const filter = useFilterContext((state) => state.filters[id]);
  const { setFilters, removeFilter } = useFilterContext((state) => state.actions);

  const [tempValue, setTempValue] = useState(filter?.value);
  useDebounce(tempValue, 400, (value) => {
    if (JSON.stringify(filter?.value) !== JSON.stringify(value)) {
      setFilters(id, { id, type: 'select', cond, value: value, meta });
      onChange && onChange(value, { setFilters, removeFilter });
    }
  });

  return (
    <Field width="100%" label={label} className={className}>
      <Suspense fallback={<Text variant="caption">Loading..</Text>}>
        <PlateTextEditor
          text={filter?.value || EMPTY_TEXT}
          onTextUpdated={(value) => setTempValue(value)}
          hideHeader
          className="w-full border-1"
        />
      </Suspense>
    </Field>
  );
};
