import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { mergeDeep } from '@apollo/client/utilities';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { CSVLink } from 'react-csv';

import { LIST_ID_TYPES } from 'constants';
import { BDDLoader } from '../bdd/bddloader';
import BDDApiError from '../bdd/bddapierror';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faEllipsisH, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import {
  buildPlayerListQuery,
  UPDATE_PLAYER_LIST,
  DELETE_PLAYER_LIST,
} from '../../apollo/queries/playerlist.queries';
import { Button, Col, Dropdown, DropdownButton, Row } from 'react-bootstrap';
import AddListPlayers from './addlistplayers';
import { toastBddApiError } from '../bdd/bddtoasts';
import BDDEditInline from '../bdd/bddeditinline';
import { SectionHeader, TooltipSpan } from '../reports';
import PlayerListRenderer from './playerlistrenderer';
import { CreateCheckpointModal, ViewCheckpointModal } from './checkpoints';
import { UserAccessModal } from './useraccess';
import { formatDate } from '../../helpers/helpers';
import { useUser } from '../../helpers/user';
import { getCurrentSeason, seasonToEndYear } from '../../helpers/hockeyutils';
import { listConstants } from '../DraftLists/constants';
import BDDErrorBoundary from 'components/bdd/bdderrorboundary';
import { ListControllerErrorContent } from './ListControllerErrorContent';
import { useAnalyticUploadData } from 'components/Lists/Builder/hooks/useAnalyticUploadData';
import { PLAYER_LIST_TYPES } from 'constants';
import { BDDTextArea } from 'components/bdd';
import { usePlayerScoutGradeSummaryLoader } from 'components/ScoutingV2/hooks';
import { Container, Row as LayoutRow } from 'components/bdd/Layout';
import { ListActionToolbar } from 'components/Lists/Builder/components/ListActionToolbar';
import { useModal } from 'components/bdd/Modal';
import ListAccessHover from 'components/Lists/components/ListAccessHover';
import LastModifiedHover from 'components/Lists/components/LastModifiedHover';
import { ScoutingRankDataLoader } from 'components/Lists/Builder/hooks/useScoutingRankDataLoader';
import { useConfirmModal } from 'components/bdd/Confirm/useConfirmModal';

const Styles = styled.div`
  .header-row {
    margin-bottom: 5px;
  }
  .edit-section {
    font-size: 0.8em;
    color: #333;
  }
  .dropdown-toggle::after {
    display: none;
  }
`;

export default function PlayerListController({
  listId: listIdArg,
  renderPlayer,
  useMyGrades, // if true, will pull grades for scout
  sortAndFilterPlayers,
  playerSize = 'lg', // "lg" or "sm"; affects the size of the side items
  hideRightSide = false, // if true, no right side info on each player
  tieredColumns = false, // if true, renders each tier as a column side by side
  showTitle = true,
  showDescription = true,
  showCreatedBy = true,
  showChangeStatus = true,
  showMenu = true,
  defaultEditMode = false,
  showEditButton = true,
  editMode: editModeArg, // if supplied, overrides state based edit mode defined here
  setEditMode: setEditModeArg,
  showCreateTierButton = true,
  showSetDraftYearButton = true,
  showDetailsButton = true,
  showDeleteButton = true,
  showAddPlayers = true,
  showUserAccessButton = true,
  showCheckpointButtons = true,
  showChangesButton = true,
  showPrimaryDraftListButton = true,
  showDownloadButton = true,
  handleDelete,
  selectedPlayers = [], // list of players that are "selected" (for things like comp modals)
  handlePlayerClick,
  clearSelectedPlayers,
  playerFields, // extra player fields to pull for each player
  includeEPStats, // bool, if true, fetch EP stats
  pollInterval = null,
  disableDragLayer = false, // if this is set, won't use draglayer.  cant use drag layer when multiple lists visible
  disableRemovePlayer = false,
  disablePlayerContextMenu = undefined, // if true, won't use a player context menu
  extraPlayerContextMenuOptions = [],
}) {
  const [showCreateCheckpoint, setShowCreateCheckpoint] = useState(false);
  const [viewCheckpoint, setViewCheckpoint] = useState(false);
  // const [showAccessModal, setShowAccessModal] = useState(false);
  const {
    showModal: showAccessModal,
    setShowModal: setShowAccessModal,
    renderModal: renderAccessModal,
  } = useModal({
    title: 'User Access',
  });
  const {
    showModal: showChangesModal,
    setShowModal: setShowChangesModal,
    renderModal: renderChangesModal,
  } = useModal({
    title: 'List Changes',
  });
  const [editModeState, setEditModeState] = useState(defaultEditMode);
  const editMode = editModeArg !== undefined ? editModeArg : editModeState;
  const setEditMode = !!setEditModeArg ? setEditModeArg : setEditModeState;
  const { isUserAdmin, user } = useUser();
  const scoutId = user.rinknetUser?.id;

  const [createTierState, setCreateTierState] = useState({
    mode: 'inactive', // "inactive", "selectStart", "selectEnd"
    start: null,
    end: null,
    error: null,
  });
  const { listId: listIdParam } = useParams();
  const listId = listIdArg ? listIdArg : listIdParam;
  const { data, loading, error } = useQuery(
    buildPlayerListQuery(playerFields, includeEPStats),
    {
      variables: { id: listId },
      pollInterval,
    }
  );

  const playerList = data?.playerList;
  var sortedAndFilteredPlayers = data?.playerList.players;
  var rinknetIds = [];
  var rinknetPlayers = [];

  rinknetPlayers = data
    ? data.playerList.players.map((player) => ({
        player,
        rinknetPlayer:
          data.playerList.idType === LIST_ID_TYPES.bdd_player_slug
            ? player.bddPlayer?.rinknetPlayer
            : player.rinknetPlayer,
      }))
    : [];

  rinknetIds = rinknetPlayers
    .filter((rp) => !!rp.rinknetPlayer)
    .map((rp) => rp.rinknetPlayer.realId);

  usePlayerScoutGradeSummaryLoader({
    rinknetIds,
  });

  const { playerToAnalytics } = useAnalyticUploadData({
    playerList: data?.playerList,
    skip: !sortAndFilterPlayers,
  });

  if (sortAndFilterPlayers) {
    sortedAndFilteredPlayers = sortAndFilterPlayers(
      rinknetPlayers,
      playerToAnalytics
    );
  }

  const tiers = sortAndFilterPlayers ? [] : data?.playerList.tiers;

  const [
    updatePlayerlist,
    { loading: updateLoading, error: updateError, called: updateCalled },
  ] = useMutation(UPDATE_PLAYER_LIST, {
    onError: (error) => toastBddApiError(error),
    update: (cache, { data }) => {
      // When updating playerList we get cache warnings from apollo
      // but i think they might require merge functions, not an update here
    },
  });
  const [deletePlayerList] = useMutation(DELETE_PLAYER_LIST, {
    update: (
      cache,
      {
        data: {
          deletePlayerList: { id },
        },
      }
    ) => {
      cache.modify({
        fields: {
          playerLists(existing = [], { readField }) {
            return existing.filter((pl) => readField('id', pl) != id);
          },
        },
      });
    },
    onCompleted: handleDelete,
  });
  const { modal: confirmModal, setShowModal: setShowConfirmModal } =
    useConfirmModal({
      prompt:
        'Are you sure you want to permanently delete this list?  Action is irreversible.',
      onConfirm: () => {
        deletePlayerList({ variables: { id: listId } });
      },
    });
  const handleDeletePlayerList = () => {
    setShowConfirmModal(true);
  };

  // Once we get a list, we need to check if user can edit to insure forced out of editMode
  useEffect(() => {
    if (!!data) {
      if (!data.playerList.isEditable) {
        setEditMode(false);
      } else {
        setEditMode(defaultEditMode);
      }
    }
  }, [data, defaultEditMode]);

  const handleChangeListDetails = (details) => {
    /* Updating list parameters */
    updatePlayerlist({
      variables: {
        input: { ...details, id: listId },
      },
    });
  };

  const handleCreateTierClick = (ev) => {
    if (createTierState.mode === 'inactive') {
      setCreateTierState({
        ...createTierState,
        mode: 'selectStart',
        msg: 'Select start of tier',
      });
    } else {
      setCreateTierState({ ...createTierState, mode: 'inactive', msg: null });
    }
  };
  // const handleTierCreation = (ev, listNumber) => {

  // }
  const handlePlayerClick_ = (ev, listNumber, playerId, player) => {
    if (createTierState.mode === 'inactive') {
      if (handlePlayerClick) handlePlayerClick(playerId, player);
      return;
    }
    if (createTierState.mode === 'selectStart') {
      setCreateTierState({
        ...createTierState,
        start: listNumber,
        mode: 'selectEnd',
        msg: 'Select end of tier',
      });
    } else if (createTierState.mode === 'selectEnd') {
      setCreateTierState({
        ...createTierState,
        end: listNumber,
        mode: 'inactive',
        msg: null,
      });
      submitTier(createTierState.start, listNumber);
    }
    ev.stopPropagation();
  };
  const submitTier = (start, end) => {
    if (!start || !end) {
      throw Error('Creating tier without start and end list number');
    }
    if (!(start <= end)) {
      toast.error('End of tier must be later than start!');
      setCreateTierState({
        ...createTierState,
        mode: 'inactive',
        start: null,
        end: null,
        msg: null,
      });
      return;
    }
    updatePlayerlist({
      variables: {
        input: { id: listId },
        tiersToAdd: [{ startsWithListNumber: start, endsWithListNumber: end }],
      },
    });
    setCreateTierState({
      ...createTierState,
      mode: 'inactive',
      start: null,
      end: null,
      msg: null,
    });
  };

  const submitPlayerMove = (
    playersToReorder,
    tiersToUpdate,
    reorderedPlayerIds
  ) => {
    const optimisticData = mergeDeep(data, {
      playerList: {
        players: reorderedPlayerIds.map((playerId) =>
          data.playerList.players.find((player) => player.playerId == playerId)
        ),
        tiers: data.playerList.tiers.map((tier) => {
          const tierToUpdate = tiersToUpdate.find((tu) => tu.id == tier.id);

          if (tierToUpdate) {
            return {
              ...tier,
              startsWithListNumber: tierToUpdate.startsWithListNumber,
              endsWithListNumber: tierToUpdate.endsWithListNumber,
            };
          }

          return tier;
        }),
      },
    });

    updatePlayerlist({
      variables: {
        input: { id: listId },
        playersToReorder: playersToReorder,
        tiersToUpdate: tiersToUpdate,
      },
      optimisticResponse: {
        updatePlayerList: optimisticData,
      },
    });
  };

  const submitTierMove = (tiersToUpdate) => {
    updatePlayerlist({
      variables: {
        input: { id: listId },
        tiersToUpdate: tiersToUpdate,
      },
    });
  };

  const handleRemovePlayer = useCallback((playerId, tiersToUpdate) => {
    updatePlayerlist({
      variables: {
        input: { id: listId },
        playersToRemove: [playerId],
        tiersToUpdate: tiersToUpdate,
      },
    });
  });

  const handleMovePlayerBetweenLists = (playersToInsertFromForeign) => {
    // @param [{ id: <player_id>, from_list_id: <list_id>, to_list_number: <destination_list_number> }]
    updatePlayerlist({
      variables: {
        input: { id: listId },
        playersToInsertFromForeign,
      },
    });
  };

  const handleAddPlayerNote = useCallback(
    (listPlayerId, note, requiresFollowUp = false) => {
      const arg = {
        variables: {
          input: { id: listId },
          playerNotesToAdd: [{ listPlayerId, note, requiresFollowUp }],
        },
      };
      updatePlayerlist(arg);
    }
  );
  const handleRemovePlayerNote = useCallback((noteId) => {
    updatePlayerlist({
      variables: {
        input: { id: listId },
        playerNotesToRemove: [noteId],
      },
    });
  });

  const handleCreateCheckpoint = (values) => {
    updatePlayerlist({
      variables: {
        input: { id: listId },
        checkpointToAdd: values,
      },
    });
  };
  const handleDeleteCheckpoint = (checkpointId) => {
    updatePlayerlist({
      variables: {
        input: { id: listId },
        checkpointsToRemove: [checkpointId],
      },
    });
  };
  const handleRevertCheckpoint = (checkpointId) => {
    updatePlayerlist({
      variables: {
        input: { id: listId },
        revertToCheckpoint: checkpointId,
      },
    });
  };

  const { idToPlayer, idToTier } = useMemo(() => {
    var idToPlayer = {};
    var idToTier = {};
    if (!!data) {
      data.playerList.players.forEach((p) => (idToPlayer[`${p.playerId}`] = p));
      data.playerList.tiers.forEach((t) => (idToTier[t.id] = t));
    }
    return { idToPlayer, idToTier };
  }, [data]);

  const listNumberToTierTitle = (listNumber) => {
    var ret = null;
    Object.values(idToTier).forEach((t) => {
      if (
        listNumber >= t.startsWithListNumber &&
        listNumber <= t.endsWithListNumber
      ) {
        ret = !!t.title ? t.title.replace(/"/g, '""') : 'Untitled Tier';
      }
    });
    return ret;
  };

  const downloadData = useMemo(() => {
    if (!!data) {
      // if "playerFields" are supplied to the query, we want to expand these as well
      // but we only go one level deep
      const playerSubFields = ['bddPlayer', 'rinknetPlayer', 'slPlayerMaster'];
      return data.playerList.players.map((p) => {
        const ret = {
          listNumber: p.listNumber,
          idType: p.idType,
          playerId: p.playerId,
          firstname: p.firstname,
          lastname: p.lastname,
          tier: listNumberToTierTitle(p.listNumber),

          notes: p.notes
            .map(
              (n, i) =>
                `Note ${i + 1}: ${n.note}${
                  n.requiresFollowUp ? ' (requires follow up)' : ''
                }`
            )
            .join('; '),
        };
        // go through each subfield
        try {
          playerSubFields.forEach((psf) => {
            // if this player has the subfield...
            if (!!p[psf]) {
              // go through each sub-sub field
              Object.keys(p[psf]).forEach((subSubField) => {
                // only add the ones that don't have any deeper nesting
                if (
                  !!p[psf][subSubField] &&
                  typeof p[psf][subSubField] != 'object'
                ) {
                  // "escape" value in case it contains quotes
                  ret[`${psf}.${subSubField}`] = p[psf][subSubField]
                    .toString()
                    .replace(/"/g, '""');
                }
              });
            }
          });
        } catch {
          console.error('Error adding extra "playerFields" to download data');
        }
        return ret;
      });
    }
    return [];
  }, [data]);

  const handleSetDraftYear = () => {
    // Setting
    const draftYear = prompt(
      'Please enter the draft year',
      getCurrentSeason().toString().slice(4)
    );
    if (!!draftYear) {
      updatePlayerlist({
        variables: {
          input: {
            id: listId,
            draftListYear: draftYear,
          },
        },
      });
    }
  };

  const handleTogglePrimaryDraftList = () => {
    if (data.playerList.isPrimaryDraftList) {
      // Unsetting
      updatePlayerlist({
        variables: {
          input: {
            id: listId,
            isPrimaryDraftList: false,
          },
        },
      });
    } else {
      const draftYear = prompt(
        'Please enter the draft year',
        getCurrentSeason().toString().slice(4)
      );
      if (!!draftYear) {
        updatePlayerlist({
          variables: {
            input: {
              id: listId,
              draftListYear: draftYear,
              isPrimaryDraftList: true,
            },
          },
        });
      }
    }
  };
  const handleTogglePrimaryGoalieList = () => {
    if (
      !!data.playerList.extras &&
      JSON.parse(data.playerList.extras).is_primary_goalie_draft_list
    ) {
      // Unsetting
      updatePlayerlist({
        variables: {
          input: {
            id: listId,
            isPrimaryGoalieDraftList: false,
          },
        },
      });
    } else {
      const draftYear = prompt(
        'Please enter the draft year',
        getCurrentSeason().toString().slice(4)
      );
      if (!!draftYear) {
        updatePlayerlist({
          variables: {
            input: {
              id: listId,
              draftListYear: draftYear,
              isPrimaryGoalieDraftList: true,
            },
          },
        });
      }
    }
  };
  const handleUpdateListExtras = (key, value) => {
    // Updating the JSON blob of extras for a list
    const extras = !!data.playerList.extras
      ? { ...data.playerList.extras }
      : {};
    const newExtras = { ...extras, [key]: value };
    updatePlayerlist({
      variables: { input: { id: listId, extras: JSON.stringify(newExtras) } },
    });
  };
  const handleUpdateAnalyticUploadSlug = () => {
    const key = listConstants.LIST_EXTRAS_ANALYTICS_UPLOAD_SLUG_KEY;
    const extras = !!data.playerList.extras
      ? { ...data.playerList.extras }
      : {};
    const existingSlug = !!extras[key] ? extras[key] : 'Default';
    const newSlug = prompt(
      'Please enter an upload slug for a new analytics file',
      existingSlug
    );
    if (!!newSlug) {
      handleUpdateListExtras(key, newSlug);
    }
  };

  if (loading) return <BDDLoader />;
  if (error) return <BDDApiError error={error} />;

  const isEditable = data.playerList.isEditable;
  const isMyList = data.playerList.isMyList;
  const listName = data.playerList.name ? data.playerList.name : 'Unnamed List';
  const draftYear = !!data.playerList.draftListYear
    ? data.playerList.draftListYear
    : [
        PLAYER_LIST_TYPES.DRAFT_LIST,
        PLAYER_LIST_TYPES.AGGREGATED_RANK_LIST,
      ].includes(playerList?.listType)
    ? seasonToEndYear(getCurrentSeason())
    : null;

  return (
    <BDDErrorBoundary
      errorContent={<ListControllerErrorContent listId={listId} />}
    >
      {rinknetIds?.length > 0 && (
        <>
          {['f', 'd', 'g'].map((position) => (
            <div key={position}>
              <ScoutingRankDataLoader
                {...{
                  rinknetIds,
                  listType: playerList?.listType,
                  queryVariables: {
                    timeRange: !!draftYear
                      ? `${draftYear - 1}${draftYear}`
                      : getCurrentSeason(),
                    position,
                    predictedGrades: true,
                  },
                }}
              />
              <ScoutingRankDataLoader
                {...{
                  rinknetIds,
                  listType: playerList?.listType,
                  queryVariables: {
                    position,
                    scoutId: useMyGrades ? scoutId : null,
                    timeRange: !!draftYear
                      ? `DRAFT_YEAR_${draftYear}`
                      : 'PAST_2_YEARS',
                    predictedGrades: false,
                    tieredNorm: !!draftYear,
                    maxViewsPerScout: !!draftYear ? 3 : null,
                  },
                }}
              />
            </div>
          ))}
        </>
      )}
      <Styles>
        <Row className="header-row">
          {showTitle && (
            <Col sm={'auto'} className="mr-auto">
              <SectionHeader style={{ textAlign: 'left' }}>
                <BDDEditInline
                  name="name"
                  defaultValue={listName}
                  onChange={(_, value) => {
                    if (value === 'Unnamed List') return;
                    handleChangeListDetails({ name: value });
                  }}
                  allowEdit={editMode}
                />
              </SectionHeader>
              {showDescription && (
                <>
                  {(editMode || !!data.playerList.description) && (
                    <div style={{ fontSize: '0.8em', fontStyle: 'italic' }}>
                      <BDDEditInline
                        name="description"
                        editingElement={BDDTextArea}
                        editingProps={{
                          style: { minWidth: '200px', width: '100%' },
                        }}
                        defaultValue={
                          data.playerList.description
                            ? data.playerList.description
                            : 'No description'
                        }
                        onChange={(_, value) => {
                          if (value === 'No description') return;
                          handleChangeListDetails({ description: value });
                        }}
                        allowEdit={editMode}
                      />
                    </div>
                  )}
                </>
              )}
            </Col>
          )}
          {showChangeStatus && (
            <Col sm={'auto'} style={{ textAlign: 'center' }}>
              {!!editMode ? (
                <small>
                  {!!createTierState.msg ? (
                    <em>{createTierState.msg}</em>
                  ) : updateLoading ? (
                    <em>Saving changes...</em>
                  ) : updateError ? (
                    <b style={{ color: 'red' }}>Something went wrong</b>
                  ) : updateCalled ? (
                    <>Changes saved</>
                  ) : (
                    <>Up to date</>
                  )}
                </small>
              ) : null}
            </Col>
          )}
          <Col sm="auto" className="ml-auto" style={{ textAlign: 'right' }}>
            {showMenu && (
              <Container>
                <LayoutRow columnGap={2} justifyContent="end">
                  <ListActionToolbar
                    playerList={data.playerList}
                    selectedPlayers={selectedPlayers}
                    tiers={tiers}
                    onPlayersCleared={clearSelectedPlayers}
                  />
                  <DropdownButton
                    variant="outline-dark"
                    size="sm"
                    style={{ display: 'inline', marginRight: '10px' }}
                    title={
                      <>
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </>
                    }
                  >
                    {isEditable && showEditButton && (
                      <>
                        <Dropdown.Item onClick={() => setEditMode(!editMode)}>
                          {editMode ? 'Done Editing' : 'Edit List'}
                        </Dropdown.Item>
                      </>
                    )}
                    {isEditable && showDetailsButton && (
                      <Dropdown.Item disabled={!editMode || true}>
                        Edit Details
                      </Dropdown.Item>
                    )}
                    {(isMyList || isUserAdmin()) && showUserAccessButton && (
                      <Dropdown.Item onClick={() => setShowAccessModal(true)}>
                        Edit User Access
                      </Dropdown.Item>
                    )}
                    <Dropdown.Divider />
                    {isEditable && showCreateTierButton && (
                      <Dropdown.Item
                        disabled={!editMode || sortAndFilterPlayers}
                        onClick={handleCreateTierClick}
                      >
                        {createTierState.mode === 'inactive' ? (
                          <>Create Tier</>
                        ) : (
                          'Cancel'
                        )}
                      </Dropdown.Item>
                    )}
                    {isEditable && showSetDraftYearButton && (
                      <Dropdown.Item
                        disabled={!editMode}
                        onClick={handleSetDraftYear}
                      >
                        Set Draft Year (
                        {!!data.playerList.draftListYear
                          ? `${data.playerList.draftListYear}`
                          : 'unset'}
                        )
                      </Dropdown.Item>
                    )}
                    {isUserAdmin() &&
                      showPrimaryDraftListButton &&
                      ['DRAFT_LIST', 'AGGREGATED_RANK_LIST'].includes(
                        data.playerList.listType
                      ) && (
                        <>
                          <Dropdown.Item onClick={handleTogglePrimaryDraftList}>
                            {data.playerList.isPrimaryDraftList
                              ? 'Unset Primary Draft List'
                              : 'Set Primary Draft List'}
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={handleTogglePrimaryGoalieList}
                          >
                            {!!data.playerList.extras &&
                            JSON.parse(data.playerList.extras)
                              .is_primary_goalie_draft_list
                              ? 'Unset Primary Goalie List'
                              : 'Set Primary Goalie List'}
                          </Dropdown.Item>

                          <Dropdown.Item
                            onClick={handleUpdateAnalyticUploadSlug}
                          >
                            Change Analytics Upload
                          </Dropdown.Item>
                        </>
                      )}
                    {showChangesButton && (
                      <Dropdown.Item onClick={() => setShowChangesModal(true)}>
                        Show Changes
                      </Dropdown.Item>
                    )}
                    {showCheckpointButtons && (
                      <>
                        {isEditable && (
                          <Dropdown.Item
                            disabled={!editMode}
                            onClick={() => setShowCreateCheckpoint(true)}
                          >
                            Create Checkpoint
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item onClick={() => setViewCheckpoint(true)}>
                          View Checkpoints
                        </Dropdown.Item>
                      </>
                    )}
                    {showDownloadButton && (
                      <>
                        <Dropdown.Item
                          as={CSVLink}
                          data={downloadData}
                          filename={`${listName}.csv`}
                          disabled={downloadData.length == 0}
                        >
                          <FontAwesomeIcon icon={faDownload} /> Download List
                        </Dropdown.Item>
                      </>
                    )}
                    {isEditable && showDeleteButton && (
                      <>
                        <Dropdown.Divider />
                        <div style={{ textAlign: 'center' }}>
                          {confirmModal}
                          <Button
                            variant="outline-danger"
                            size="sm"
                            disabled={!editMode}
                            onClick={handleDeletePlayerList}
                          >
                            Delete List
                          </Button>
                        </div>
                      </>
                    )}
                  </DropdownButton>
                </LayoutRow>
              </Container>
            )}
            {showEditButton && data.playerList.isEditable && (
              <Button
                variant="outline-success"
                size="sm"
                onClick={() => setEditMode(!editMode)}
                style={{ width: '110px' }}
              >
                {editMode ? 'Done Editing' : 'Edit'}
              </Button>
            )}
            {showCreatedBy && (
              <div>
                <small>
                  Created by {data.playerList.user.username}{' '}
                  <TooltipSpan
                    placement="bottom"
                    content={`Last modified ${formatDate(
                      data.playerList.lastModified
                    )} by ${data.playerList.lastModifiedUser.username}`}
                  >
                    ({formatDate(data.playerList.dateCreated)})
                  </TooltipSpan>
                </small>
              </div>
            )}
          </Col>
        </Row>
        <Container padding={1}>
          <PlayerListRenderer
            listId={listId}
            editMode={editMode && !sortAndFilterPlayers}
            idToPlayer={idToPlayer}
            idToTier={idToTier}
            tiers={tiers}
            players={sortedAndFilteredPlayers}
            tieredColumns={tieredColumns}
            renderPlayer={
              renderPlayer ? (p) => renderPlayer(p, data.playerList) : null
            }
            playerSize={playerSize}
            hideRightSide={hideRightSide}
            submitPlayerMove={submitPlayerMove}
            movePlayerBetweenLists={handleMovePlayerBetweenLists}
            submitTierMove={submitTierMove}
            handleRemovePlayer={handleRemovePlayer}
            lastModified={data.playerList.lastModified}
            updateLoading={updateLoading}
            selectedPlayers={selectedPlayers}
            handlePlayerClick={handlePlayerClick_}
            handleAddPlayerNote={handleAddPlayerNote}
            handleRemovePlayerNote={handleRemovePlayerNote}
            showPlayerNotes={true}
            disableRemovePlayer={disableRemovePlayer}
            disableDragLayer={disableDragLayer} // if this is set, won't use draglayer.  cant use drag layer when multiple lists visible
            disablePlayerContextMenu={
              disablePlayerContextMenu === undefined
                ? playerSize != 'sm'
                : disablePlayerContextMenu
            }
            extraPlayerContextMenuOptions={extraPlayerContextMenuOptions}
          />
        </Container>
        {editMode && showAddPlayers && <AddListPlayers listId={listId} />}
      </Styles>
      {showCreateCheckpoint && (
        <CreateCheckpointModal
          show={showCreateCheckpoint}
          handleClose={() => setShowCreateCheckpoint(false)}
          handleSubmit={handleCreateCheckpoint}
        />
      )}
      {viewCheckpoint && (
        <ViewCheckpointModal
          show={viewCheckpoint}
          handleClose={() => setViewCheckpoint(false)}
          handleDelete={handleDeleteCheckpoint}
          handleRevert={handleRevertCheckpoint}
          jumpToCreate={() => {
            setViewCheckpoint(false);
            setShowCreateCheckpoint(true);
          }}
          listId={listId}
          renderPlayer={renderPlayer}
          playerFields={playerFields}
          editMode={editMode}
        />
      )}
      {showAccessModal &&
        renderAccessModal(
          <ListAccessHover listId={listId} showHeader={false} />
        )}
      {showChangesModal &&
        renderChangesModal(
          <LastModifiedHover list={data.playerList} width="auto" />
        )}
    </BDDErrorBoundary>
  );
}
