import { itemTypes } from 'constants';
import produce from 'immer';
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useDragLayer } from 'react-dnd';
import { DragLayer } from '.';
import Draggable from '../draggable';
import Droppable from '../droppable';
import { Column, Container } from '../Layout';

function moveByIndex(list, fromIndex, toIndex) {
  return produce(list, (draft) => {
    const element = draft[fromIndex];
    draft.splice(fromIndex, 1);
    draft.splice(toIndex, 0, element);
  });
}

function insertAtIndex(list, element, toIndex) {
  return produce(list, (draft) => {
    draft.splice(toIndex, 0, element);
  });
}

const idToUqStr = (ids) => ids.join('-');

export const DraggableList = React.memo(
  ({
    listId, // ID for this list, passed back in "onChange", useful when players are moved between lists
    ids,
    idToData, // optionally include extra data with each droppable
    renderItem, // (id, index, isDragging) => JSX
    renderBeforeId,
    renderAfterId,
    renderDragLayer,
    onChange, // (listId, ids, movedItem, movedId, droppedIndex, droppedOnId, isForeign) => void
    allowForeign = false, // if true, will accept dropped items that aren't in set of "ids"
    noDragPreview = true,
    updateOnIdsChange = true, // if false, won't update list to reflect change in "ids" prop
    updateOnIdsSetChange = true, // like above, but only updates list when the set changes (not the order)
    disabled,
    listColumnGap = 2,
    itemType = itemTypes.DRAGGABLE_LIST,
  }) => {
    const [list, setList] = useState(ids);
    // const [idStr, setIdStr] = useState(idToUqStr);
    const [hasDropped, setHasDropped] = useState(false);
    const [currentId, setCurrentId] = useState(null);

    useEffect(() => {
      if (updateOnIdsChange) {
        setList(ids);
        setCurrentId(null);
      }
      if (updateOnIdsSetChange) {
        const listSet = new Set(list);
        if (list.length != ids.length || !ids.every((i) => listSet.has(i))) {
          setList(ids);
          setCurrentId(null);
        }
      }
    }, [idToUqStr(ids)]);

    const { item, isDragging, didDrop, dropResult } = useDragLayer(
      (monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        isDragging: monitor.isDragging(),
        didDrop: monitor.didDrop(),
      })
    );

    const prevDidDrop = useMemo(() => didDrop, [didDrop]);

    useEffect(() => {
      if (!!item) {
        // we're dragging an item, but haven't dropped it yet
        setHasDropped(false);
      }
      const isForeign = !ids.find((id) => currentId == id);
      if (isForeign && !hasDropped) {
        // Foreign item was hovered but never dropped
        setList(ids);
      }
      setCurrentId(item?.data?.id);
    }, [item]);

    useEffect(() => {
      if (didDrop) setHasDropped(true);
    }, [didDrop]);

    return (
      <Container>
        {!!renderDragLayer && <DragLayer>{renderDragLayer()}</DragLayer>}
        <Column gap={listColumnGap}>
          {list.map((id, index) => (
            <Container key={id}>
              {!!renderBeforeId && renderBeforeId(id, index)}

              <Droppable
                acceptItemTypes={[itemType]}
                onHover={(item, ...rest) => {
                  if (item.data.id == id) return;
                  const fromIndex = list.findIndex((id) => id == item.data.id);

                  if (fromIndex >= 0) {
                    // dragging an item within the list
                    setList(moveByIndex(list, fromIndex, index));
                  } else if (allowForeign) {
                    // dragging a new item over the list
                    setList(insertAtIndex(list, item.data.id, index));
                  }
                }}
                onDrop={(item) => {
                  // finished moving a player
                  const isForeign = !ids.find((id) => id == item.data.id);
                  if (!allowForeign && isForeign) {
                    console.warn(
                      'Attempting to add foreign item to draggable list but "allowForeign" set to false'
                    );
                    return;
                  }

                  onChange({
                    listId,
                    ids: list,
                    movedItem: item,
                    movedId: item.data.id,
                    droppedIndex: index,
                    droppedOnId: ids[index],
                    isForeign,
                  });
                }}
              >
                <Draggable
                  data={{
                    id: id,
                    ...idToData?.[id],
                  }}
                  onEnd={(item, monitor) => {
                    if (!monitor.didDrop()) {
                      // dropped outside the list
                      setList(ids);
                    }
                  }}
                  itemType={itemType}
                  noDragPreview={noDragPreview}
                  draggable={!disabled}
                >
                  {renderItem(id, index, isDragging)}
                </Draggable>
              </Droppable>
              {!!renderAfterId && renderAfterId(id, index)}
            </Container>
          ))}
        </Column>
      </Container>
    );
  }
);
